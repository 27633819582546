// DotAnimation.js
import React from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "styles/colors";

const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px); /* Adjust the value as needed */
  }
`;

const moveUpDownLarge = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(47px); /* Adjust the value as needed */
  }
`;

const moveUpDownLargeMobile = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px); /* Adjust the value as needed */
  }
`;

const DotContainer = styled.div<{ large: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: ${(props) => (props.large ? "87" : "30")}px;
  width: ${(props) => (props.large ? "228" : "84")}px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 959px) {
    width: ${(props) => (props.large ? "160" : "84")}px;
    height: ${(props) => (props.large ? "90" : "30")}px;
  }
`;

const Dot = styled.div<{ large: boolean }>`
  width: ${(props) => (props.large ? "46" : "18")}px;
  height: ${(props) => (props.large ? "46" : "18")}px;
  background-color: ${Colors.ACCENT};
  border-radius: 50%;
  position: relative;
  animation: ${(props) => (props.large ? moveUpDownLarge : moveUpDown)} 1s
    infinite ease-in-out;

  @media (max-width: 959px) {
    width: ${(props) => (props.large ? "30" : "18")}px;
    height: ${(props) => (props.large ? "30" : "18")}px;

    animation: ${(props) => (props.large ? moveUpDownLargeMobile : moveUpDown)}
      1s infinite ease-in-out;
  }
`;

const Dot1 = styled(Dot)`
  animation-delay: 0s;
  top: 0; /* Start at the top */
`;

const Dot2 = styled(Dot)`
  animation-delay: 0.5s;
  bottom: 0; /* Start at the bottom */
`;

const Dot3 = styled(Dot)`
  animation-delay: 0s;
  top: 0; /* Start at the top */
`;

const Dot4 = styled(Dot)`
  animation-delay: 0.5s;
  bottom: 0; /* Start at the bottom */
`;

interface LoadingAnimationProps {
  isLarge: boolean;
}
export const LoadingAnimation = (props: LoadingAnimationProps) => {
  const { isLarge } = props;
  return (
    <DotContainer large={isLarge}>
      <Dot1 large={isLarge} />
      <Dot2 large={isLarge} />
      <Dot3 large={isLarge} />
      <Dot4 large={isLarge} />
    </DotContainer>
  );
};
