export enum ErrorCodes {
  INVALID_TOKEN = "E206",
  NOT_VERIFIED = "E203",
  NOT_LOGGED_IN = "E200",
  INVALID_PASSWORD = "E201",
  PASSWORD_CHANGED = "E204",
  INVALID_EMAIL = "E400",
  INVALID_INPUT = "E102",
  NOT_ON_WAITLIST = "E101",
  USER_EXISTS = "E100",
}
