import { useEffect, useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { useToggleBoolean } from "../hooks/useToggleBoolean";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoginInfo } from "../interfaces/user";
import {
  FullWidthTextInput,
  StyledButtonFullWidth,
  StyledSubtitle,
  StyledTitle,
  StyledLink,
  StyledForm,
  CenterContentOnPage,
  GradientBackground,
  LandingLogo,
} from "../styles/sharedComponents";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import Logo from "../assets/cbgpt-logo-white.png";
import { Colors } from "styles/colors";
import { PendingVerificationComponent } from "./PendingVerification.component";
import { useSendVerificationToken } from "hooks/useSendVerificationToken";
import { useLogout } from "hooks/useLogout";
import { removeToken } from "helpers/auth";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const SignUpRedirect = styled.div`
  font-weight: 600;
  margin-top: 24px;
  font-size: 16px;
  color: ${Colors.WHITE};
`;
const StyledLogo = styled(LandingLogo)`
  margin-bottom: 41px;
`;

const Title = styled(StyledTitle)`
  margin-bottom: 30px;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 51px;
  margin-top: 5px;
`;

const ForgotPasswordLink = styled(StyledLink)`
  && {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.01em;
  }
`;

const PasswordInput = styled(FullWidthTextInput)`
  && {
    margin-bottom: 0px;
  }
`;

export const LoginComponent = () => {
  const [showPassword, toggleShowPassword] = useToggleBoolean();
  const {
    login,
    isLoading,
    passwordError,
    emailError,
    resetEmailError,
    resetPasswordError,
  } = useLogin();
  const [needsVerification, setNeedsVerification] = useState(false);
  const { sendVerificationToken } = useSendVerificationToken();

  useEffect(() => {
    removeToken();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginInfo>({ resolver: yupResolver(schema) });

  const email = watch("email");
  const password = watch("password");

  const isDisabled = !email || !password;

  // useEffect(() => {
  //   if (emailError) {
  //     resetEmailError();
  //   }
  // }, [email, emailError]);

  // useEffect(() => {
  //   if (passwordError) {
  //     resetPasswordError();
  //   }
  // }, [password, passwordError]);

  const onNotVerified = () => {
    sendVerificationToken(email, () => {});
    setNeedsVerification(true);
  };

  const onSubmit: SubmitHandler<LoginInfo> = (data) => {
    login(data, onNotVerified);
  };

  if (needsVerification) {
    return <PendingVerificationComponent email={email} />;
  }

  return (
    <GradientBackground>
      <CenterContentOnPage>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledLogo src={Logo} alt="Logo" />
          <Title invert>Welcome back!</Title>
          <FullWidthTextInput
            label="Email"
            variant="outlined"
            {...register("email")}
            error={!!errors.email || emailError}
            helperText={errors.email ? errors.email.message : emailError}
          />
          <PasswordInput
            label="Password"
            variant="outlined"
            {...register("password")}
            error={!!errors.password || passwordError}
            helperText={
              errors.password ? errors.password.message : passwordError
            }
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleShowPassword()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ForgotPasswordContainer>
            <ForgotPasswordLink href="/forgotPassword" isunderlined={true}>
              Forgot Password?
            </ForgotPasswordLink>
          </ForgotPasswordContainer>

          <StyledButtonFullWidth
            type="submit"
            disabled={isDisabled}
            loading={isLoading}
          >
            Sign in
          </StyledButtonFullWidth>

          <SignUpRedirect>
            Do not have an account?{" "}
            <StyledLink href="/signUp" isunderlined>
              Sign up
            </StyledLink>
          </SignUpRedirect>
        </StyledForm>
      </CenterContentOnPage>
    </GradientBackground>
  );
};
