import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { getHeaders } from "helpers/auth";

export const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendForgotPasswordEmail = (email: string) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.FORGOT_PASSWORD), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    }).then((response): any => {
      setIsLoading(false);

      console.log(response);

      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((data: any) => {
        console.log(data);
      });
    });
  };

  return { sendForgotPasswordEmail, isLoading };
};
