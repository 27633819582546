export const setToken = (accessToken: string) => {
  sessionStorage.setItem("cbgptToken", accessToken);
};

export const removeToken = () => {
  sessionStorage.removeItem("cbgptToken");
};
export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("cbgptToken") || ""}`,
  };
};
