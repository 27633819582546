import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { ErrorCodes } from "constants/errorCodes";
import { useDispatch } from "react-redux";
import { setUser } from "helpers/userSlice";
import { User } from "interfaces/user";
import { updateConversation } from "helpers/conversationSlice";
import { Conversation } from "interfaces/backend";
import { getHeaders } from "helpers/auth";

export const useRenameConversation = (
  conversation: Conversation,
  onComplete: () => void
) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const renameConversation = (newTitle: string) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.GET_CONVERSATION, { id: conversation._id }), {
      method: "PATCH",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        title: newTitle,
      }),
    }).then((response): any => {
      setIsLoading(false);
      const updatedConversation = {
        ...conversation,
        title: newTitle,
      };
      dispatch(
        updateConversation({
          conversationId: conversation._id,
          updatedConversation: updatedConversation,
        })
      );
      onComplete();
    });
  };

  return { renameConversation, isLoading };
};
