import { useGetMe } from "hooks/useGetMe";
import { useLogin } from "../hooks/useLogin";
import {
  StyledForm,
  StyledButtonFullWidth,
  StyledSubtitle,
  StyledTitle,
  CenterContentOnPage,
} from "../styles/sharedComponents";
import { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { useSendVerificationToken } from "hooks/useSendVerificationToken";

const StyledBackground = styled(CenterContentOnPage)`
  background-color: ${Colors.BACKGROUND};
`;

interface VerifyEmailProps {
  email: string;
}

export const PendingVerificationComponent = (props: VerifyEmailProps) => {
  const { email } = props;
  const { sendVerificationToken, isLoading } = useSendVerificationToken();

  return (
    <StyledBackground>
      <StyledForm>
        <StyledTitle>Check your email</StyledTitle>
        <StyledSubtitle>
          We've sent a verification link to {email}
        </StyledSubtitle>

        <StyledButtonFullWidth
          loading={isLoading}
          onClick={() => sendVerificationToken(email, () => {})}
        >
          Resend the Link
        </StyledButtonFullWidth>
      </StyledForm>
    </StyledBackground>
  );
};
