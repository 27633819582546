import styled from "styled-components";
import { botUser } from "../constants/sender";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "interfaces/redux";

const IconContainer = styled.div`
  background-color: black;
  color: white;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 800;
  font-size: 18px;
`;

const UserIcon = () => {
  const userData = useSelector((state: AppState) => state.userData.user);

  return (
    <IconContainer>
      {userData?.firstName?.slice(0, 1)}
      {userData?.lastName?.slice(0, 1)}
    </IconContainer>
  );
};

export default UserIcon;
