import "./App.css";
import { SignUpComponent } from "./components/SignUp.component";
import { LoginComponent } from "./components/Login.component";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ForgotPasswordComponent } from "./components/ForgotPassword";
import { AppRoutes } from "./constants/routes";
import { VerifyEmailComponent } from "components/VerifyEmail.component";
import { InterfaceWrapperComponent } from "components/InterfaceWrapper.component";
import { LandingPageComponent } from "components/LandingPage.component";
import { SetNewPasswordComponent } from "components/SetNewPassword.component";

function App() {
  return (
    <Router>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <div>
        <Routes>
          <Route path="/*" Component={LandingPageComponent} />
          <Route path={AppRoutes.CHAT} Component={InterfaceWrapperComponent} />
          <Route path={AppRoutes.LOGIN} Component={LoginComponent} />
          <Route path={AppRoutes.SIGN_UP} Component={SignUpComponent} />
          <Route
            path={AppRoutes.FORGOT_PASSWORD}
            Component={ForgotPasswordComponent}
          />
          <Route
            path={`${AppRoutes.VERIFY_EMAIL}/:token`}
            Component={VerifyEmailComponent}
          />
          <Route
            path={`${AppRoutes.SET_NEW_PASSWORD}/:token`}
            Component={SetNewPasswordComponent}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
