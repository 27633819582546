import { useEffect, useState } from "react";
import { CreateAccountInfo } from "../interfaces/user";
import { useCreateAccount } from "../hooks/useCreateAccount";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";
import {
  StyledTitle,
  StyledSubtitle,
  StyledLink,
  StyledForm,
  FullWidthTextInput,
  StyledButtonFullWidth,
  CenterContentOnPage,
  GradientBackground,
  LandingLogo,
} from "../styles/sharedComponents";
import Logo from "../assets/cbgpt-logo-white.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AppRoutes } from "constants/routes";
import { Colors } from "styles/colors";
import { PendingVerificationComponent } from "./PendingVerification.component";

const schema = yup.object().shape({
  fname: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
  lname: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one symbol"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
});

const TermsOfService = styled.div`
  font-family: Spartan;
  font-size: 14px;
  font-weight: 300;
  line-height: 15.68px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 39px;
  color: ${Colors.WHITE};
`;

const StyledLogo = styled(LandingLogo)`
  margin-bottom: 65px;

  @media (max-width: 959px) {
    margin-bottom: 28px;
  }
`;

const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledInput = styled(FullWidthTextInput)`
  @media (max-width: 959px) {
    margin-bottom: 21px;
    & .MuiInputBase-root {
      height: 50px;
    }
  }
`;

const LeftInput = styled(StyledInput)`
  margin-right: 26px !important;
`;

const LoginRedirect = styled.div`
  font-weight: 600;
  margin-top: 24px;
  color: ${Colors.WHITE};
`;

const Subtitle = styled(StyledSubtitle)`
  margin-top: 5px;
  font-size: 23px;
  @media (max-width: 959px) {
    font-size: 22px;
  }
`;

const Title = styled(StyledTitle)`
  @media (max-width: 959px) {
    font-size: 27px;
  }
`;

const StyledBackground = styled(GradientBackground)`
  @media (max-width: 959px) {
    height: 100%;
  }
`;

const StyledContainer = styled(CenterContentOnPage)``;

export const SignUpComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreateAccountInfo>({ resolver: yupResolver(schema) });

  const fname = watch("fname");
  const lname = watch("lname");
  const email = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const [createAccount, isLoading, ErrorToast] = useCreateAccount();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  const isDisabled =
    !fname || !lname || !email || !password || !confirmPassword;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit: SubmitHandler<CreateAccountInfo> = (data) => {
    createAccount(data, () => setAccountCreated(true));
  };

  if (accountCreated) {
    return (
      <PendingVerificationComponent
        email={email}
      ></PendingVerificationComponent>
    );
  }

  return (
    <StyledBackground>
      <ErrorToast />
      <StyledContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledLogo src={Logo} alt="Logo" />
          <Title invert>Welcome to chatblackgpt.</Title>
          <Subtitle invert>A culturally informed AI</Subtitle>
          <NameDiv>
            <LeftInput
              {...register("fname")}
              label="First Name"
              variant="outlined"
              error={!!errors.fname}
              helperText={errors.fname ? errors.fname.message : ""}
            />
            <StyledInput
              {...register("lname")}
              label="Last Name"
              variant="outlined"
              error={!!errors.lname}
              helperText={errors.lname ? errors.lname.message : ""}
            />
          </NameDiv>
          <StyledInput
            {...register("email")}
            label="Email"
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
          <StyledInput
            {...register("password")}
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                    style={{ color: "black" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledInput
            label="Confirm Password"
            {...register("confirmPassword")}
            variant="outlined"
            type={showConfirmPassword ? "text" : "password"}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword ? errors.confirmPassword.message : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                    style={{ color: "black" }}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TermsOfService>
            By registering for an account, you are consenting to our{" "}
            <StyledLink
              href="https://chatblackgpt-rules-rewule4.gamma.site/"
              target="_blank"
              isunderlined
            >
              Terms of Service
            </StyledLink>
          </TermsOfService>
          <StyledButtonFullWidth
            disabled={isDisabled}
            loading={isLoading}
            type="submit"
          >
            Sign Up
          </StyledButtonFullWidth>
          <LoginRedirect>
            Already have an account?{" "}
            <StyledLink isunderlined href={AppRoutes.LOGIN}>
              Login
            </StyledLink>
          </LoginRedirect>
        </StyledForm>
      </StyledContainer>
    </StyledBackground>
  );
};
