import styled from "styled-components";
import { Colors } from "styles/colors";
import { StyledTitle } from "styles/sharedComponents";
import { ReactComponent as PlusIcon } from "assets/plus.svg";

const BlankStateTitle = styled(StyledTitle)`
  @media (max-width: 959px) {
    font-size: 29px;
  }
`;

const BlankStateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    margin-bottom: 50px;
  }
`;

const SamplePromptHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 25px 26px 0;
  width: 100%;
  max-width: 599px;

  @media (max-width: 959px) {
    margin-top: 15px;
  }
`;

const SamplePrompt = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  height: 124px;
  color: ${Colors.WHITE};
  padding: 26px;
  border-radius: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20.16px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  background: linear-gradient(
    to right,
    ${Colors.ACCENT},
    ${Colors.SECONDARY_ACCENT}
  );

  &:hover,
  &:active {
    background: linear-gradient(
      to right,
      ${Colors.DARK_ACCENT},
      ${Colors.DARK_SECONDARY_ACCENT}
    );
  }

  @media (max-width: 959px) {
    font-size: 14px;
    height: 100px;
    padding: 16px;
    font-weight: 600;
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 24px;
  height: 24px;

  @media (max-width: 959px) {
    width: 20px;
    height: 20px;
  }
`;

const LeftPrompt = styled(SamplePrompt)`
  margin-right: 15px;
`;

interface BlankStateProps {
  selectPrompt: (prompt: string) => void;
}

const [prompt1, prompt2, prompt3, prompt4] = [
  "How does ChatBlackGPT differ from ChatGPT?",
  "Who are some hidden figures in Black history?",
  "What does Juneteenth celebrate?",
  "How are Black people marginalized in media and AI?",
];

export const BlankStateComponent = (props: BlankStateProps) => {
  const { selectPrompt } = props;

  return (
    <BlankStateContainer>
      <BlankStateTitle>How can I help you today?</BlankStateTitle>
      <SamplePromptHorizontalContainer>
        <LeftPrompt onClick={() => selectPrompt(prompt1)}>
          {prompt1}
          <StyledPlusIcon />
        </LeftPrompt>
        <SamplePrompt onClick={() => selectPrompt(prompt2)}>
          {prompt2}
          <StyledPlusIcon />
        </SamplePrompt>
      </SamplePromptHorizontalContainer>
      <SamplePromptHorizontalContainer>
        <LeftPrompt onClick={() => selectPrompt(prompt3)}>
          {prompt3}
          <StyledPlusIcon />
        </LeftPrompt>
        <SamplePrompt onClick={() => selectPrompt(prompt4)}>
          {prompt4} <StyledPlusIcon />
        </SamplePrompt>
      </SamplePromptHorizontalContainer>
    </BlankStateContainer>
  );
};
