import { Colors } from "./colors";

export const setCSSVariables = (colors: typeof Colors) => {
  const root = document.documentElement;

  Object.keys(colors).forEach((key) => {
    root.style.setProperty(
      `--${key.toLowerCase().replace(/_/g, "-")}`,
      colors[key as keyof typeof Colors]
    );
  });
};
