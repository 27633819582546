import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { ErrorCodes } from "constants/errorCodes";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "helpers/userSlice";
import { User } from "interfaces/user";
import {
  deleteConversation,
  selectConversation,
} from "helpers/conversationSlice";
import { AppState } from "interfaces/redux";
import { getHeaders } from "helpers/auth";

export const useDeleteConversation = (conversationId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );

  const deleteConversationWrapper = (onComplete: () => void) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.DELETE_CONVERSATION, { id: conversationId }), {
      method: "DELETE",
      credentials: "include",
      headers: getHeaders(),
    }).then(() => {
      setIsLoading(false);
      dispatch(deleteConversation(conversationId));
      dispatch(selectConversation(undefined));
      onComplete();
    });
  };

  return { deleteConversationWrapper, isLoading };
};
