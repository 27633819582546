import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { String } from "aws-sdk/clients/acm";
import { getHeaders } from "helpers/auth";

export const useVerifyAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const verifyAccount = (token: String, onFailure: () => void) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.VERIFY_ACCOUNT, { token: token }), {
      method: "GET",
      credentials: "include",
      headers: getHeaders(),
    }).then((response): any => {
      setIsLoading(false);
      if (response.ok) {
        setIsVerified(true);
      } else {
        onFailure();
      }
    });
  };

  return { verifyAccount, isVerified, isLoading };
};
