import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { Conversation } from "../interfaces/backend";
import { useDispatch } from "react-redux";
import { setConversations } from "helpers/conversationSlice";
import { getHeaders } from "helpers/auth";

export const useGetConversations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getConversations = () => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.GET_CONVERSATIONS), {
      method: "GET",
      credentials: "include",
      headers: getHeaders(),
    }).then((response): any => {
      setIsLoading(false);

      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((data: { data: { data: Conversation[] } }) => {
        const conversations = data.data.data;
        dispatch(setConversations(conversations));
      });
    });
  };

  return { getConversations, isLoading };
};
