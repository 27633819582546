import { useGetMe } from "hooks/useGetMe";
import { InterfaceComponent } from "./Interface.component";
import { useGetConversations } from "hooks/useGetConversations";
import { LoadingScreen } from "./Loading/LoadingScreen.component";
import { useEffect } from "react";

export const InterfaceWrapperComponent = () => {
  const { getMe, isLoading: isGetMeLoading } = useGetMe();
  const { getConversations, isLoading: isGetConversationsLoading } =
    useGetConversations();

  useEffect(() => {
    getMe();
    getConversations();
  }, []);
  return isGetMeLoading || isGetConversationsLoading ? (
    <LoadingScreen />
  ) : (
    <InterfaceComponent />
  );
};
