import { ConversationComponent } from "./Conversation.component";
import { Colors } from "../../styles/colors";
import { ConversationGroup } from "interfaces/backend";
import styles from "./group.module.css";

interface ConversationGroupProps {
  conversationGroup: ConversationGroup;
}

const groupContainerStyles: React.CSSProperties = {
  width: "100%",
  marginBottom: "18px",
};

export const ConversationGroupComponent = (props: ConversationGroupProps) => {
  const { conversationGroup } = props;
  return (
    <div style={groupContainerStyles}>
      <div className={styles.conversationTitle}>{conversationGroup.title}</div>

      {conversationGroup.conversations.map((conversation) => (
        <ConversationComponent
          key={conversation._id}
          conversation={conversation}
          {...props}
        />
      ))}
    </div>
  );
};
