import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/colors";
import { ReactComponent as SendIcon } from "../../assets/send.svg";
import { ReactComponent as StopIcon } from "../../assets/rounded-square.svg";

import { StyledScrollBar } from "../../styles/sharedComponents";
import { AppState } from "interfaces/redux";
import { useDispatch, useSelector } from "react-redux";
import { setShowTypeWriter } from "helpers/typewriterSlice";

const ChatInputContainer = styled.div`
  flex: 1;
  padding: 6px;
  padding-left: 20px;
  border-radius: 30px;
  font-size: 16px;
  outline: none;
  width: 100%;
  position: relative;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  @media (min-width: 1200px) and (max-width: 1429px) {
    width: 830px;
  }

  @media (min-width: 1430px) {
    width: 970px;
  }

  @media (max-width: 959px) {
    margin-bottom: 7px;
  }
`;

const Disclaimer = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

// &::before {
//     content: '';
//     position: absolute;
//     top: -110px;
//     left: 0;
//     width: calc(100% - 12px);
//     height: 110px;
//     background: linear-gradient(
//       to bottom,
//       rgba(240, 241, 242, 0),
//       rgba(240, 241, 242, 0.8)
//     );

const ChatButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? Colors.DISABLED : Colors.ACCENT};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px;
  height: 48px !important;
  width: 48px !important;
  margin-left: 5px;

  &:hover,
  &:active {
    background-color: ${(props) =>
      props.disabled ? "auto" : Colors.DARK_ACCENT};
  }

  @media (max-width: 959px) {
    height: 38px !important;
    width: 38px !important;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const ChatContainer = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

const ChatInput = styled.textarea<{ disabled: boolean }>`
  border: none;
  width: calc(100% - 55px);
  resize: none;
  font-size: 16px;
  line-height: 1.5;
  min-height: 1.5em; /* 1.5em is the line height */
  max-height: calc(1.5em * 10); /* 10 lines */
  overflow-y: auto;
  outline: none;

  overflow-x: hidden;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "auto")};

  /* Scrollbar Track */
  &::-webkit-scrollbar {
    width: 4px;
  }

  background-color: ${Colors.WHITE};

  /* Scrollbar Handle/Thumb */
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.ACCENT}; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }

  /* Scrollbar Track */
  &::-webkit-scrollbar-track {
    background: ${Colors.WHITE}; /* Color of the scrollbar track */
  }

  /* Scrollbar Corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the scrollbar corner */
  }
`;

interface ChatInputBarProps {
  handleSubmitMessage: (message: string) => void;
  truncateMessage: () => void;
  isDisabled: boolean;
}

export const ChatInputBar = (props: ChatInputBarProps) => {
  const { handleSubmitMessage, truncateMessage, isDisabled } = props;
  const [inputText, setInputText] = useState("");
  const dispatch = useDispatch();

  const showTypewriter = useSelector(
    (state: AppState) => state.typewriterData.showTypewriter
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to auto to get the correct scrollHeight
      textareaRef.current.style.height = "auto";
      // Set the height to the scrollHeight
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputText]);

  const onSubmit = () => {
    handleSubmitMessage(inputText);
    setInputText("");
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
      setInputText("");
    }
  };

  const handleChatButtonClick = () => {
    if (showTypewriter) {
      dispatch(setShowTypeWriter(false));
    } else {
      onSubmit();
    }
  };

  return (
    <ChatContainer>
      <ChatInputContainer>
        <ChatInput
          value={inputText}
          disabled={showTypewriter || isDisabled}
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="What's on your mind?"
          rows={1}
        ></ChatInput>
        <ChatButton
          disabled={isDisabled || (inputText.trim() === "" && !showTypewriter)}
          onClick={handleChatButtonClick}
        >
          {showTypewriter ? <StopIcon /> : <SendIcon />}
        </ChatButton>
      </ChatInputContainer>
      <Disclaimer>
        chatblackgpt can make mistakes, so check important details.
      </Disclaimer>
    </ChatContainer>
  );
};
