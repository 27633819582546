import styled from "styled-components";
import Typewriter from "components/Typewriter.component";
import { ReactComponent as DownLeftArrowIcon } from "assets/down-left-arrow.svg";
import { Colors } from "styles/colors";
import { LoadingAnimation } from "components/Loading/LoadingAnimation.component";
import Gradient from "assets/gradient-background-chat.png";
import FeedbackIcon from "assets/Feedback.svg";
import { Chat } from "interfaces/backend";
import { useState } from "react";
import { AppState } from "interfaces/redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowTypeWriter } from "helpers/typewriterSlice";
import { ShareFeedbackComponent } from "components/ShareFeedback";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const StyledFeedbackIcon = styled(FeedbackIcon)`
  margin-right: 17px;
  margin-top: 12px;
  cursor: pointer;
  display: block;
  @media (max-width: 959px) {
    margin-top: 6px;
  }
`;

const FeedbackIconImage = styled.img`
  margin-right: 17px;
  margin-top: 12px;
  cursor: pointer;
  display: block;
  width: 26px;
  height: 26px;
  @media (max-width: 959px) {
    margin-top: 6px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  white-space: pre-wrap;
  border-radius: 30px;
  overflow: hidden;
  padding: 32px 24px;
  color: ${Colors.WHITE};
  position: relative;
  font-size: 17px;

  @media (max-width: 959px) {
    padding: 24px;
    font-weight: 500;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${Gradient});
    background-size: cover; /* Ensures the image covers the div */
    background-repeat: no-repeat;
    border-radius: inherit; /* Ensures the pseudo-element respects the parent border-radius */
    z-index: -1; /* Places the pseudo-element behind any content in the div */
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -6px;
`;

const BotName = styled.div`
  color: ${Colors.ACCENT};
  font-size: 20px;
  font-weight: 700;
  padding-left: 24px;
  padding-right: 4px;
`;

const AlignLoadingAnimation = styled.div`
  margin-top: 6px;
  width: 84px;
  margin-left: 24px;
`;

const AlignLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Divider = styled.hr`
  margin-bottom: 45px;
`;

export const BotMessageComponent = (props: {
  message: Chat;
  isLatest: boolean;
  previousMessage?: Chat;
}) => {
  const { message, isLatest, previousMessage } = props;
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const showTypewriter = useSelector(
    (state: AppState) => state.typewriterData.showTypewriter
  );
  const dispatch = useDispatch();

  return (
    <>
      <ShareFeedbackComponent
        isFeedbackOpen={isFeedbackOpen}
        setIsFeedbackOpen={setIsFeedbackOpen}
        messageId={message._id}
        previousMessageId={previousMessage?._id}
      ></ShareFeedbackComponent>
      <MessageContainer>
        <Heading>
          <BotName>chatblackgpt</BotName>
          <DownLeftArrowIcon></DownLeftArrowIcon>
        </Heading>
        {message.content === "" ? (
          <AlignLoadingAnimation>
            <LoadingAnimation isLarge={false} />
          </AlignLoadingAnimation>
        ) : showTypewriter && isLatest ? (
          <TextContainer>
            <Typewriter
              text={message.content}
              onComplete={() => dispatch(setShowTypeWriter(false))}
            ></Typewriter>
          </TextContainer>
        ) : (
          <AlignLeft>
            <TextContainer>{message.content}</TextContainer>
            {isLatest && (
              <FeedbackIconImage
                src={FeedbackIcon}
                onClick={() => setIsFeedbackOpen(true)}
              />
            )}
          </AlignLeft>
        )}
      </MessageContainer>
      {!isLatest && message.content !== "" && <Divider />}
    </>
  );
};
