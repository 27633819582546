export enum Endpoint {
  // GET
  GET_CONVERSATIONS = "/conversations",
  VERIFY_ACCOUNT = "/users/verifyAccount/{}",
  LOGOUT = "/users/logout",
  GET_CONVERSATION = "/conversations/{}",
  GET_ME = "/users/getMe",
  GET_CHAT_BY_ID = "/conversations/chat/{}",
  GET_ALL_CHATS_OF_CONVERSATION = "/conversations/{}",
  GET_CHAT = "/chat/{}",

  // POST
  // CREATE_USER = "users/signup",
  CREATE_USER = "/users/signup",
  LOGIN = "/users/login",
  FORGOT_PASSWORD = "/users/forgotPassword",
  SET_NEW_PASSWORD = "/users/resetPassword/{}",
  SEND_VERIFICATION_TOKEN = "/users/sendVerificationToken",
  CREATE_CONVERSATION = "/conversations",
  CREATE_CHAT = "/conversations/{}/chats",
  REFRESH_TOKEN = "/users/refreshToken",
  SUBMIT_FEEDBACK = "/feedbacks",

  // PATCH
  UPDATE_ME = "/users/updateMe",
  UPDATE_PASSWORD = "/users/updatePassword/{}",
  UPDATE_MY_PASSWORD = "/users/updateMyPassword",
  UPDATE_CONVERSATION = "/conversations/{}",
  UPDATE_CHAT = "/conversations/chat/{}",

  // DELETE
  DELETE_ME = "/users/deleteMe",
  DELETE_CONVERSATION = "/conversations/{}",
  DELETE_CHAT = "/conversations/chat/{}",
}

const BASE_URL =
  "https://ffbtwg2a7hfdw7cgeaxb53srdi0hbxii.lambda-url.us-east-1.on.aws/api";

// const BASE_URL = "";

export const getEndpoint = (
  endpoint: Endpoint,
  values: { [key: string]: string } = {}
): string => {
  switch (endpoint) {
    case Endpoint.GET_CONVERSATION:
      return BASE_URL + formatString(Endpoint.GET_CONVERSATION, values["id"]);
    case Endpoint.VERIFY_ACCOUNT:
      return BASE_URL + formatString(Endpoint.VERIFY_ACCOUNT, values["token"]);
    case Endpoint.UPDATE_PASSWORD:
      return BASE_URL + formatString(Endpoint.UPDATE_PASSWORD, values["token"]);
    case Endpoint.DELETE_CONVERSATION:
      return (
        BASE_URL + formatString(Endpoint.DELETE_CONVERSATION, values["id"])
      );
    case Endpoint.SET_NEW_PASSWORD:
      return (
        BASE_URL + formatString(Endpoint.SET_NEW_PASSWORD, values["token"])
      );
    case Endpoint.UPDATE_CONVERSATION:
      return (
        BASE_URL + formatString(Endpoint.UPDATE_CONVERSATION, values["id"])
      );
    case Endpoint.UPDATE_CHAT:
      return BASE_URL + formatString(Endpoint.UPDATE_CHAT, values["id"]);
    case Endpoint.DELETE_CHAT:
      return BASE_URL + formatString(Endpoint.DELETE_CHAT, values["id"]);
    case Endpoint.GET_ALL_CHATS_OF_CONVERSATION:
      return (
        BASE_URL +
        formatString(Endpoint.GET_ALL_CHATS_OF_CONVERSATION, values["id"])
      );
    case Endpoint.CREATE_CHAT:
      return BASE_URL + formatString(Endpoint.CREATE_CHAT, values["id"]);
    default:
      return BASE_URL + endpoint;
  }
};

function formatString(template: string, ...values: any[]): string {
  return template.replace(/{}/g, () => values.shift());
}
