import { useEffect, useState } from "react";
import { ChatComponent } from "./Chat";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import { ConversationHistoryComponent } from "./ConversationHistory/ConversationHistory.component";
import { styled } from "styled-components";
import { Colors } from "../styles/colors";
import { ReactComponent as ExpandIcon } from "../assets/Expand.svg";
import Logo from "../assets/cbgpt-logo-black.png";
import { useErrorToast } from "hooks/useErrorToast";

const StyledLogo = styled.img`
  width: 128px;
`;

const InterfaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.BACKGROUND};
  width: 100%;
  height: 100%;
  font-family: "Spartan", sans-serif;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  position: fixed;
  cursor: pointer;
  top: 19px;
  height: 35px;
  width: 35px;
  left: 12px;

  @media (max-width: 959px) {
    height: 25px;
    width: 25px;
  }
`;

const DrawerPlaceholder = styled.div`
  width: 298px;
  height: 100px;
  flex-shrink: 0;
`;

const MenuBar = styled.div<{ isOpen: boolean }>`
  z-index: 9;
  width: ${(props) => (props.isOpen ? "calc(100vw - 298px)" : "100vw")};
  height: 73px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  background: ${Colors.BACKGROUND};
  margin-left: ${(props) => (props.isOpen ? "298" : "0")}px;

  @media (max-width: 959px) {
    width: 100vw;
    margin-left: 0;
  }
`;

export const InterfaceComponent = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { openErrorToast, ErrorToast } = useErrorToast();

  // useEffect(() => {
  //   openErrorToast();
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 845 && !isMobile) {
        setIsMobile(true);
        setIsOpen(false);
      } else if (window.innerWidth >= 845 && isMobile) {
        setIsMobile(false);
        setIsOpen(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const toggleDrawer = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const ConversationHistory = (
    <ConversationHistoryComponent toggleDrawer={toggleDrawer} />
  );

  return (
    <InterfaceContainer>
      <ErrorToast></ErrorToast>
      <MenuBar isOpen={isOpen}>
        <StyledExpandIcon
          onClick={() => {
            setIsOpen(true);
          }}
        ></StyledExpandIcon>
        <StyledLogo src={Logo} alt="Logo" />
      </MenuBar>
      {isMobile ? (
        <>
          <Drawer
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            transitionDuration={{ enter: 500, exit: 500 }}
            PaperProps={{
              sx: {
                height: `calc(100% - 40px)`, // 100% height minus 20px top and bottom
                top: "20px", // 20px from the top
                bottom: "20px", // 20px from the bottom
                width: "300px", // Customize the width of the drawer
                position: "fixed", // Ensure it's positioned correctly within the viewport
                overflow: "hidden",
                borderRadius: "0px 26px 26px 0px",
              },
            }}
          >
            {ConversationHistory}
          </Drawer>
        </>
      ) : (
        <>
          <Drawer
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            transitionDuration={{ enter: 500, exit: 500 }}
            hideBackdrop={true}
            variant="persistent"
            PaperProps={{
              sx: {
                height: `calc(100% - 40px)`, // 100% height minus 20px top and bottom
                top: "20px", // 20px from the top
                bottom: "20px", // 20px from the bottom
                width: "300px", // Customize the width of the drawer
                position: "absolute", // Ensure it's positioned correctly within the viewport
                overflow: "hidden",
                borderRadius: "0px 26px 26px 0px",
                boxShadow: "none",
              },
            }}
          >
            {ConversationHistory}
          </Drawer>
          {isOpen && !isMobile && <DrawerPlaceholder></DrawerPlaceholder>}
        </>
      )}
      <ChatComponent />
    </InterfaceContainer>
  );
};
