import { useEffect, useRef, useState } from "react";
import { MessageComponent } from "./Message.component";
import styled from "styled-components";
import { Conversation } from "../../interfaces/backend";
import { ChatInputBar } from "./ChatInputBar.component";
import { Colors } from "../../styles/colors";
import { BlankStateComponent } from "./BlankState.component";
import { useManageConversation } from "hooks/useManageConversation";
import { AppState } from "interfaces/redux";
import { useSelector } from "react-redux";
import { usePollForMessage } from "hooks/usePollForMessage";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: calc(100dvh - 20px);
  margin: 0 12px 20px 12px;
  width: 100%;
  z-index: 5;
  padding-top: 73px;
`;

const MessagesContainer = styled.div`
  display: block;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding-right: 8px;

  /* Scrollbar Track */
  &::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Scrollbar Handle/Thumb */
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.ACCENT}; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }

  /* Scrollbar Track */
  &::-webkit-scrollbar-track {
    background: ${Colors.BACKGROUND}; /* Color of the scrollbar track */
  }

  /* Scrollbar Corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the scrollbar corner */
  }

  width: 100%;

  @media (min-width: 1200px) and (max-width: 1429px) {
    width: 830px;
  }

  @media (min-width: 1430px) {
    width: 970px;
  }
`;

interface ChatComponentProps {
  selectedConversation?: Conversation;
}

export const ChatComponent = (props: ChatComponentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );
  const messages = useSelector(
    (state: AppState) => state.conversationsData.messages
  );
  const loadingConversations = useSelector(
    (state: AppState) => state.conversationsData.loadingConversations
  );

  const showTypewriter = useSelector(
    (state: AppState) => state.typewriterData.showTypewriter
  );

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current!.scrollHeight;
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (showTypewriter && containerRef.current) {
      interval = setInterval(() => {
        scrollToBottom();
      }, 50);
    }
    return () => {
      if (interval) clearInterval(interval);
      scrollToBottom();
    };
  }, [showTypewriter]);

  const { sendMessage, truncateMessage } = useManageConversation();
  const { pollForMessage, isLoading: isPollLoading } = usePollForMessage();

  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation, messages]);

  const pendingBotMessage = messages[messages.length - 1]?.role === "USER";
  const isResponseLoading =
    loadingConversations.includes(selectedConversation?._id) ||
    selectedConversation?._id === "tempConversation";

  useEffect(() => {
    const delayFunctionCall = () => {
      if (pendingBotMessage && !isResponseLoading && !isPollLoading) {
        pollForMessage(selectedConversation?._id);
      }
    };

    const timer = setTimeout(() => {
      delayFunctionCall();
    }, 5000);

    return () => clearTimeout(timer);
  }, [pendingBotMessage, isResponseLoading, isPollLoading]);

  const handleSubmitMessage = (message: string) => {
    sendMessage(message);
  };

  return (
    <ChatContainer>
      {!selectedConversation ? (
        <BlankStateComponent
          selectPrompt={handleSubmitMessage}
        ></BlankStateComponent>
      ) : (
        <MessagesContainer ref={containerRef}>
          {messages.map((message, index) => (
            <MessageComponent
              previousMessage={index > 0 ? messages[index - 1] : undefined}
              message={message}
              key={message._id}
              isLatest={index >= messages.length - 1}
            ></MessageComponent>
          ))}
          {pendingBotMessage && (
            <MessageComponent
              message={{
                _id: "loadingMessage",
                content: "",
                createdAt: "",
                conversationId: selectedConversation._id,
                role: "ASSISTANT",
              }}
              key="loadingMessage"
              isLatest={false}
            ></MessageComponent>
          )}
        </MessagesContainer>
      )}
      <ChatInputBar
        isDisabled={pendingBotMessage || isResponseLoading}
        truncateMessage={truncateMessage}
        handleSubmitMessage={handleSubmitMessage}
      ></ChatInputBar>
    </ChatContainer>
  );
};
