import { useState } from "react";
import {
  FullWidthTextInput,
  StyledForm,
  StyledButtonFullWidth,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
  CenterContentOnPage,
} from "../styles/sharedComponents";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "../assets/left-arrow.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Colors } from "styles/colors";
import { useForgotPassword } from "hooks/useForgotPassword";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email address"),
});

const BackToLoginLink = styled(StyledLink)`
  && {
    margin-top: 36px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${Colors.DARK_GRAY_TEXT};
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  margin-right: 4px;
`;

const StyledBackground = styled(CenterContentOnPage)`
  background-color: ${Colors.BACKGROUND};
`;

interface ForgotPasswordInfo {
  email: string;
}

export const ForgotPasswordComponent = () => {
  const { sendForgotPasswordEmail, isLoading } = useForgotPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ForgotPasswordInfo>({ resolver: yupResolver(schema) });

  const [sentToEmail, setSentToEmail] = useState("");
  const email = watch("email");

  const onSubmit: SubmitHandler<ForgotPasswordInfo> = (data) => {
    sendForgotPasswordEmail(data.email);
    setSentToEmail(data.email);
  };

  const ForgotPasswordForm = (
    <>
      <StyledTitle>Forgot password?</StyledTitle>
      <StyledSubtitle>
        No worries, we will send you reset instructions.
      </StyledSubtitle>
      <FullWidthTextInput
        {...register("email")}
        label="Email"
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ""}
      />

      <StyledButtonFullWidth
        type="submit"
        disabled={!email}
        loading={isLoading}
      >
        Reset Password
      </StyledButtonFullWidth>
    </>
  );

  const ResetLinkSentPage = (
    <>
      <StyledTitle>Check your email</StyledTitle>
      <StyledSubtitle>
        We sent a password reset link to {sentToEmail}.
      </StyledSubtitle>

      <StyledButtonFullWidth type="submit" loading={isLoading}>
        Resend the link
      </StyledButtonFullWidth>
    </>
  );

  return (
    <StyledBackground>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {sentToEmail ? ResetLinkSentPage : ForgotPasswordForm}
        <BackToLoginLink isunderlined={false} href="/login">
          <StyledLeftArrowIcon />
          Back to login
        </BackToLoginLink>
      </StyledForm>
    </StyledBackground>
  );
};
