import { useState } from "react";
import { CreateAccountInfo, CreateAccountResponse } from "../interfaces/user";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { setCookies } from "../helpers/cookie";
import { StatusMessage } from "interfaces/backend";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { useSendVerificationToken } from "./useSendVerificationToken";
import { getHeaders } from "helpers/auth";
import { ErrorCodes } from "constants/errorCodes";
import { useErrorToast } from "./useErrorToast";

export const useCreateAccount = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { openErrorToast, ErrorToast } = useErrorToast();
  const { sendVerificationToken, isLoading: isSendVerificationTokenLoading } =
    useSendVerificationToken();

  const createAccount = (info: CreateAccountInfo, onComplete: () => void) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.CREATE_USER), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        firstName: info.fname,
        lastName: info.lname,
        email: info.email,
        username: info.email,
        role: "USER",
        bio: "My name is Jane Doe",
        password: info.password,
        passwordConfirm: info.confirmPassword,
      }),
    }).then((response): any => {
      setIsLoading(false);
      response.json().then((data: any) => {
        console.log(data);
        if (!response.ok) {
          if (data.errorCode === ErrorCodes.NOT_ON_WAITLIST) {
            openErrorToast(data.message);
          } else if (data.errorCode === ErrorCodes.USER_EXISTS) {
            openErrorToast("An account already exists with that email.");
          } else {
            openErrorToast();
          }
        } else {
          sendVerificationToken(info.email, onComplete);
        }
      });
    });
  };

  return [
    createAccount,
    isLoading || isSendVerificationTokenLoading,
    ErrorToast,
  ] as const;
};
