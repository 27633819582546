import { Endpoint, getEndpoint } from "constants/endpoints";
import {
  Chat,
  Conversation,
  CreateConversationResponse,
  SendMessageResponse,
} from "interfaces/backend";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConversation,
  selectConversation,
  updateConversation,
  setMessages,
  addMessage,
} from "../helpers/conversationSlice";
import { getCurrentDateTimeString } from "helpers/dateFormatter";
import { AppState } from "interfaces/redux";
import { setShowTypeWriter } from "helpers/typewriterSlice";
import { getHeaders } from "helpers/auth";

export const useCreateConversation = () => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );
  const messages = useSelector(
    (state: AppState) => state.conversationsData.messages
  );

  const [response, setResponse] = useState<
    CreateConversationResponse | undefined
  >(undefined);

  useEffect(() => {
    if (
      !response ||
      selectedConversation?._id !== "tempConversation" ||
      messages.length !== 1 ||
      messages[0]?.content !== response.promptChat.content
    ) {
      return;
    }

    const updatedConversation: Conversation = {
      ...selectedConversation,
      title: response.title,
      _id: response.promptChat.conversationId,
      mlContextId: response.mlContextId,
    };

    dispatch(
      updateConversation({
        conversationId: "tempConversation",
        updatedConversation: updatedConversation,
      })
    );
    dispatch(selectConversation(updatedConversation));
    dispatch(setMessages([response.promptChat, response.responseChat]));
    dispatch(setShowTypeWriter(true));
  }, [response]);

  const createConversation = (message: string) => {
    setResponse(undefined);
    const tempConversation: Conversation = {
      _id: "tempConversation",
      title: "New chat",
      createdAt: getCurrentDateTimeString(),
      mlContextId: "temp",
    };
    dispatch(addConversation(tempConversation));
    dispatch(selectConversation(tempConversation));

    fetch(getEndpoint(Endpoint.CREATE_CONVERSATION), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        prompt: message,
      }),
    }).then((response): any => {
      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }
      response.json().then((body: { data: CreateConversationResponse }) => {
        const data = body.data;
        setResponse(data);
      });
    });
  };

  return {
    createConversation,
  };
};
