import styled from "styled-components";
import { useGetConversations } from "../../hooks/useGetConversations";
import { ConversationGroupComponent } from "./ConversationGroup.component";
import { Button } from "@mui/material";
import { Conversation, ConversationGroup } from "../../interfaces/backend";
import { Colors } from "../../styles/colors";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as CollapseIcon } from "../../assets/Collapse.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/Feedback.svg";
import { AppState } from "interfaces/redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { subDays, startOfDay } from "date-fns";
import { selectConversation, setMessages } from "helpers/conversationSlice";
import { useDispatch } from "react-redux";
import Logo from "../../assets/cbgpt-logo-black.png";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import AddImage from "../../assets/accent-add.svg";
import { ShareFeedbackComponent } from "components/ShareFeedback";

const StyledAddImage = styled.img`
  position: absolute;
  top: 10px;
  left: 13px;
  cursor: pointer;
  height: 25px;
  width: 25px;
`;

const StyledCollapseIcon = styled(CollapseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  @media (max-width: 959px) {
    height: 25px;
    width: 25px;
    top: 10px;
  }
`;

const StyledLogo = styled.img`
  margin-top: 59px;
  margin-bottom: 30px;
  width: 151px;

  @media (max-width: 959px) {
    margin-top: 30px;
    width: 140px;
  }
`;

const Container = styled.div`
  width: 298px !important;
  background-color: ${Colors.WHITE};
  position: relative;
  height: 100%;
`;
const CtaContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
  }

  @media (max-width: 959px) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const CtaButton = styled(Button)`
  width: 196px;
  height: 50px;
  margin: 15px;

  && {
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
    border-radius: 69px;

    background-color: ${Colors.ALT_BUTTON};

    &:hover,
    &:active {
      background-color: ${Colors.ACCENT};
    }

    @media (max-width: 959px) {
      display: none;
    }
  }
`;

const CtaTextContainer = styled.div`
  margin-left: 8px;
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 959px) {
    font-size: 15px;
  }
`;

const ConversationsContainer = styled.div`
  height: calc(100dvh - 172px - 40px - 32px - 40px - 100px);

  overflow-y: auto;
  overflow-x: hidden;

  /* Scrollbar Track */
  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  /* Scrollbar Handle/Thumb */
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.ACCENT}; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }

  /* Scrollbar Track */
  &::-webkit-scrollbar-track {
    background: ${Colors.WHITE}; /* Color of the scrollbar track */
  }

  /* Scrollbar Corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the scrollbar corner */
  }

  @media (max-width: 959px) {
    height: calc(100dvh - 172px - 40px - 32px - 50px);
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  height: 20px;
  width: 20px;
  margin-right: 7px;
  margin-bottom: 3px;
`;

const StyledFeedbackIcon = styled(FeedbackIcon)`
  height: 20px;
  width: 20px;
  margin-right: 7px;
  margin-bottom: 3px;
`;

const LogoutButton = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 4px 11px;
  width: fit-content;
  border-radius: 50px;

  &:hover {
    background-color: ${Colors.ALT_BUTTON_LIGHT};
  }

  background-color: ${(props) =>
    props.isSelected ? Colors.ALT_BUTTON_LIGHT : "none"};
`;

const FeedbackButton = styled(LogoutButton)`
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  padding-left: 9px;
  bottom: 14px;
  left: 1px;
  padding-top: 14px;
  border-top: 2px solid ${Colors.BACKGROUND};
  width: 100%;

  @media (max-width: 959px) {
    bottom: 31px;
  }
`;

const getLabel = (
  conversation: Conversation,
  today: Date,
  sevenDaysAgo: Date,
  thirtyDaysAgo: Date
): string => {
  const createdAt = startOfDay(new Date(conversation.createdAt));

  if (createdAt >= today) {
    return "Recent conversations";
  } else if (createdAt >= sevenDaysAgo) {
    return "Last 7 Days";
  } else if (createdAt >= thirtyDaysAgo) {
    return "Last 30 Days";
  } else if (createdAt.getFullYear() === today.getFullYear()) {
    return createdAt.toLocaleString("en", { month: "long" });
  } else {
    return createdAt.getFullYear().toString();
  }
};

export const ConversationHistoryComponent = (props: {
  toggleDrawer: (isOpen: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const conversations = useSelector(
    (state: AppState) => state.conversationsData.conversations
  );
  const dispatch = useDispatch();
  const [conversationGroups, setConversationGroups] = useState<
    ConversationGroup[]
  >([]);

  const { toggleDrawer } = props;

  const populateConversationGroups = (conversations: Conversation[]) => {
    const twentyFourHoursAgo = subDays(new Date(), 1);
    const today = startOfDay(new Date());
    const sevenDaysAgo = subDays(today, 7);
    const thirtyDaysAgo = subDays(today, 30);
    const groups: ConversationGroup[] = [];

    conversations?.forEach((conversation, i) => {
      const label = getLabel(
        conversation,
        twentyFourHoursAgo,
        sevenDaysAgo,
        thirtyDaysAgo
      );

      if (groups.length === 0 || groups[groups.length - 1].title !== label) {
        groups.push({ title: label, conversations: [conversation] });
      } else {
        groups[groups.length - 1].conversations.push(conversation);
      }
    });

    setConversationGroups(groups);
  };

  useEffect(() => {
    if (conversations.length > 0) {
      populateConversationGroups(conversations);
    }
  }, [conversations]);

  const onClickNewChat = () => {
    toggleDrawer(false);
    dispatch(selectConversation(undefined));
    dispatch(setMessages([]));
  };

  const onClickCollapse = () => {
    toggleDrawer(false);
  };

  return (
    <Container>
      <ShareFeedbackComponent
        isFeedbackOpen={isFeedbackOpen}
        setIsFeedbackOpen={setIsFeedbackOpen}
      ></ShareFeedbackComponent>
      <StyledAddImage
        onClick={onClickNewChat}
        src={AddImage}
        alt="New Chat"
        className="non-mobile-hide"
      />
      <StyledCollapseIcon onClick={onClickCollapse}></StyledCollapseIcon>
      <CtaContainer>
        <StyledLogo src={Logo} alt="Logo" />
        <CtaButton
          variant="contained"
          onClick={onClickNewChat}
          className="styledButton"
          disabled={conversations[0]?._id === "tempConversation"}
        >
          <AddIcon />
          <CtaTextContainer>New chat</CtaTextContainer>
        </CtaButton>
      </CtaContainer>
      <ConversationsContainer>
        {conversationGroups.map((group, i) => (
          <ConversationGroupComponent
            conversationGroup={group}
            key={i}
            {...props}
          ></ConversationGroupComponent>
        ))}
      </ConversationsContainer>
      <ButtonsContainer>
        <FeedbackButton
          onClick={() => setIsFeedbackOpen(true)}
          isSelected={isFeedbackOpen}
        >
          <StyledFeedbackIcon /> Give Feedback
        </FeedbackButton>
        <LogoutButton
          onClick={() => navigate(AppRoutes.LOGIN)}
          isSelected={false}
        >
          <StyledLogoutIcon /> Logout
        </LogoutButton>
      </ButtonsContainer>
    </Container>
  );
};
