import { configureStore } from "@reduxjs/toolkit";
import conversationReducer from "./conversationSlice";
import userSlice from "./userSlice";
import typewriterSlice from "./typewriterSlice";

const store = configureStore({
  reducer: {
    conversationsData: conversationReducer,
    userData: userSlice,
    typewriterData: typewriterSlice,
  },
});

export default store;
