import { useEffect } from "react";
import { useTypewriter } from "../hooks/useTypewriter";

const speed = 2;

interface TypewriterProps {
  text: string;
  onComplete: () => void;
}
const Typewriter = (props: TypewriterProps) => {
  const { text, onComplete } = props;
  const displayText = useTypewriter(text, speed);

  useEffect(() => {
    if (text === displayText) {
      onComplete();
    }
  }, [text, displayText]);

  return <div>{displayText}</div>;
};

export default Typewriter;
