export enum Colors {
  BACKGROUND = "#F0F1F2",
  INPUT_BACKGROUND = "#F5F5F5",
  WHITE = "#FFFFFF",
  ACCENT = "#4A00C2",
  SECONDARY_ACCENT = "#B50FA5",
  DARK_ACCENT = "#330087",
  DARK_SECONDARY_ACCENT = "#790D6E",
  BORDER = "#EFEFEF",
  BLACK = "#000000",
  DARK_GRAY_TEXT = "#414143",
  GRAY_TEXT = "#6A6969",
  LIGHT_ACCENT = "#563cf421",
  MID_ACCENT = "#563cf46e",
  ULTRA_LIGHT_ACCENT = "#563cf40a",
  DISABLED = "#563CF46E",
  SECONDARY_HOVER = "#563CF420",
  BRIGHT_RED = "#ff0000",
  ERROR_RED = "#E20000",
  LIGHT_ERROR_RED = "#FFDEDE",
  ALT_BUTTON = "rgba(74, 0, 194, 0.5)",
  ALT_BUTTON_LIGHT = "rgba(74, 0, 194, 0.13)",
  DIVIDER = "#00000024",
}
