import styled from "styled-components";
import { Conversation } from "../../interfaces/backend";
import { ReactComponent as MenuVerticalIcon } from "../../assets/menu-vertical.svg";
import Popover from "@mui/material/Popover";

import { Colors } from "../../styles/colors";
import { useRef, useState } from "react";
import { ConversationMenu } from "./ConversationMenu";
import { useSelector } from "react-redux";
import { AppState } from "interfaces/redux";
import { useDispatch } from "react-redux";
import {
  deleteConversation,
  selectConversation,
} from "helpers/conversationSlice";
import { useLoadMessages } from "hooks/useLoadMessages";
import { setShowTypeWriter } from "helpers/typewriterSlice";

const ConversationContainer = styled.div`
  cursor: pointer;
  margin: 12px;
  padding: 7px 0;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    flex-shrink: 0;
  }
`;

const conversationContainerStyles: React.CSSProperties = {
  cursor: "pointer",
  margin: "12px",
  padding: "7px 0",
  height: "50px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const TextContainer = styled.div<{ selected: boolean }>`
  padding: 8px;
  border-radius: 50px;
  background-color: ${(props) =>
    props.selected ? Colors.LIGHT_ACCENT : Colors.WHITE};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
`;

const StyledMenuVerticalIcon = styled(MenuVerticalIcon)`
  cursor: pointer;
`;

type ConversationComponentProps = {
  conversation: Conversation;
};

export const ConversationComponent = (props: ConversationComponentProps) => {
  const { conversation } = props;
  const textContainerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const loadMessages = useLoadMessages();

  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (
      !selectedConversation ||
      conversation._id !== selectedConversation?._id
    ) {
      if (selectedConversation?._id === "tempConversation") {
        dispatch(deleteConversation(selectedConversation._id));
      }
      dispatch(setShowTypeWriter(false));
      dispatch(selectConversation(conversation));
      loadMessages(conversation._id);
    }
  };

  const isSelected = selectedConversation?._id === conversation._id;

  return (
    <ConversationContainer
      style={conversationContainerStyles}
      onClick={handleOnClick}
    >
      <TextContainer selected={isSelected} ref={textContainerRef}>
        <Title>{conversation.title}</Title>

        {isSelected && (
          <StyledMenuVerticalIcon
            onClick={() => {
              setIsMenuOpen(true);
            }}
          />
        )}
      </TextContainer>
      <Popover
        open={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        anchorEl={textContainerRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: "16px",
            },
          },
        }}
      >
        <ConversationMenu conversation={conversation} />
      </Popover>
    </ConversationContainer>
  );
};
