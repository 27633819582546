import { ReactComponent as PencilIcon } from "../../assets/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import { Conversation } from "../../interfaces/backend";
import { Button, Modal } from "@mui/material";
import { useState } from "react";
import { Colors } from "../../styles/colors";
import {
  ModalContainer,
  ModalTitle,
  StyledButton,
  StyledSecondaryButton,
} from "../../styles/sharedComponents";
import { useDeleteConversation } from "hooks/useDeleteConversation";
import { useRenameConversation } from "hooks/useRenameConversation";

const MenuOptionText = styled.div`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const RenameInput = styled.input`
  font-family: Spartan, sans-serif;
  border: 2px solid ${Colors.ACCENT};
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  padding: 25px 18px;
  border-radius: 20px;
  margin-top: 28px;
  margin-bottom: 60px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DeleteModal = styled(ModalContainer)`
  max-width: 307px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 35px;
  padding-bottom: 40px;
`;

const DeleteModalButton = styled(StyledSecondaryButton)`
  && {
    color: ${Colors.ACCENT};
    margin-top: 26px;
    width: 100px;
    margin-right: 0px;
  }
`;

export const ConversationMenu = (props: { conversation: Conversation }) => {
  const { conversation } = props;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editedTitle, setEditedTitle] = useState(conversation.title);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleEditClick = () => {
    setEditedTitle(conversation.title);
    setIsEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteOpen(false);
  };

  const { deleteConversationWrapper } = useDeleteConversation(conversation._id);
  const { renameConversation } = useRenameConversation(
    conversation,
    handleCloseEditModal
  );

  const handleDeleteConversation = () => {
    deleteConversationWrapper(handleCloseDeleteModal);
  };

  const handleRenameConversation = () => {
    renameConversation(editedTitle);
  };

  return (
    <>
      <Modal open={isEditOpen} onClose={handleCloseEditModal}>
        <ModalContainer>
          <ModalTitle>Rename this chat</ModalTitle>
          <RenameInput
            value={editedTitle}
            autoFocus={true}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <ButtonsContainer>
            <StyledSecondaryButton hasMargin onClick={handleCloseEditModal}>
              Cancel
            </StyledSecondaryButton>
            <StyledButton
              disabled={!editedTitle || editedTitle === conversation.title}
              onClick={handleRenameConversation}
            >
              Update
            </StyledButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal open={isDeleteOpen} onClose={handleCloseDeleteModal}>
        <DeleteModal>
          <ModalTitle>Delete this chat?</ModalTitle>
          <ButtonsContainer>
            <DeleteModalButton hasMargin onClick={handleCloseDeleteModal}>
              Cancel
            </DeleteModalButton>
            <DeleteModalButton
              hasMargin={false}
              onClick={handleDeleteConversation}
            >
              Delete
            </DeleteModalButton>
          </ButtonsContainer>
        </DeleteModal>
      </Modal>
      <div
        style={{
          padding: "17px 11px",
          paddingBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={handleEditClick}
      >
        <PencilIcon /> <MenuOptionText>Rename</MenuOptionText>
      </div>
      <Divider variant="fullWidth" />
      <div
        style={{
          padding: "17px 11px",
          paddingTop: "10px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsDeleteOpen(true)}
      >
        <DeleteIcon /> <MenuOptionText>Delete</MenuOptionText>
      </div>
    </>
  );
};
