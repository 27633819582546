import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { ErrorCodes } from "constants/errorCodes";
import { useDispatch } from "react-redux";
import { setUser } from "helpers/userSlice";
import { User } from "interfaces/user";
import { getHeaders } from "helpers/auth";

export const useGetMe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getMe = () => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.GET_ME), {
      method: "GET",
      credentials: "include",
      headers: getHeaders(),
    }).then((response): any => {
      setIsLoading(false);
      response
        .json()
        .then((data: { data: { data: User }; errorCode?: string }) => {
          if (
            !response.ok ||
            (data?.errorCode &&
              [
                (ErrorCodes.INVALID_TOKEN.toString(),
                ErrorCodes.NOT_VERIFIED.toString()),
              ].includes(data.errorCode))
          ) {
            navigate(AppRoutes.LOGIN);
          } else {
            dispatch(setUser(data.data.data));
          }
        });
    });
  };

  return { getMe, isLoading };
};
