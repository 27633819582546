import styled from "styled-components";
import { Colors } from "../styles/colors";
import Gradient from "../../assets/gradient-background-chat.png";
import { ReactComponent as FeedbackIcon } from "../../assets/Feedback.svg";
import { Modal } from "@mui/material";
import {
  ModalContainer,
  StyledSecondaryButton,
  StyledButton,
  ModalTitle,
} from "styles/sharedComponents";
import { useState } from "react";
import { useSubmitFeedback } from "hooks/useSubmitFeedback";

const FeedbackInput = styled.textarea`
  font-family: Spartan, sans-serif;
  border: 2px solid ${Colors.ACCENT};
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  padding: 25px 18px;
  border-radius: 20px;
  margin-top: 28px;
  margin-bottom: 60px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ShareFeedbackComponent = (props: {
  isFeedbackOpen: boolean;
  messageId?: string;
  previousMessageId?: string;
  setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isFeedbackOpen, messageId, previousMessageId, setIsFeedbackOpen } =
    props;
  const [feedback, setFeedback] = useState("");
  const { submitFeedback } = useSubmitFeedback();

  const handleCloseFeedbackModel = () => {
    setFeedback("");
    setIsFeedbackOpen(false);
  };

  const handleSubmitFeedback = () => {
    submitFeedback(feedback, messageId, previousMessageId);
    handleCloseFeedbackModel();
  };

  return (
    <>
      <Modal open={isFeedbackOpen} onClose={handleCloseFeedbackModel}>
        <ModalContainer>
          <ModalTitle>Share your feedback</ModalTitle>
          <FeedbackInput
            value={feedback}
            autoFocus={true}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <ButtonsContainer>
            <StyledSecondaryButton hasMargin onClick={handleCloseFeedbackModel}>
              Cancel
            </StyledSecondaryButton>
            <StyledButton disabled={!feedback} onClick={handleSubmitFeedback}>
              Submit
            </StyledButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};
