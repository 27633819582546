import { useState } from "react";
import { LoginInfo } from "../interfaces/user";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import Cookies from "universal-cookie";
import { setCookies } from "helpers/cookie";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { ErrorCodes } from "constants/errorCodes";
import { getHeaders, setToken } from "helpers/auth";

const cookies = new Cookies();

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();

  const login = (info: LoginInfo, onNotVerified: () => void) => {
    setEmailError("");
    setPasswordError("");
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.LOGIN), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        email: info.email,
        password: info.password,
      }),
    }).then((response): any => {
      setIsLoading(false);
      console.log(response);

      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((data: any) => {
        if (response.ok) {
          if (data.data?.user?.isVerified === false) {
            onNotVerified();
          } else {
            setToken(data.accessToken);
            navigate(AppRoutes.CHAT);
          }
        } else {
          if (data.errorCode === ErrorCodes.INVALID_EMAIL) {
            setEmailError(data.message);
          } else {
            setPasswordError(data.message);
          }
        }
      });
    });
  };

  const resetPasswordError = () => {
    setPasswordError("");
  };
  const resetEmailError = () => {
    setEmailError("");
  };

  return {
    login,
    isLoading,
    passwordError,
    emailError,
    resetPasswordError,
    resetEmailError,
  } as const;
};
