import { Endpoint, getEndpoint } from "constants/endpoints";
import { getHeaders } from "helpers/auth";
import {
  addMessage,
  setMessages,
  updateConversation,
} from "helpers/conversationSlice";
import { setShowTypeWriter } from "helpers/typewriterSlice";
import {
  Chat,
  LoadMessagesResponse,
  SendMessageResponse,
} from "interfaces/backend";
import { AppState } from "interfaces/redux";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePollForMessage = () => {
  const dispatch = useDispatch();

  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );
  const messages = useSelector(
    (state: AppState) => state.conversationsData.messages
  );

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<LoadMessagesResponse | undefined>(
    undefined
  );

  useEffect(() => {
    if (!response || messages[messages.length - 1].role === "ASSISTANT") {
      setIsLoading(false);
      return;
    }

    console.log(response);

    const chats = response.chats;
    const lastChat = chats[chats.length - 1];
    if (
      lastChat.role === "ASSISTANT" &&
      lastChat.conversationId === selectedConversation._id
    ) {
      dispatch(addMessage(lastChat));
      dispatch(setShowTypeWriter(true));
      if (selectedConversation.title === "New Chat") {
        const updatedConversation = {
          ...selectedConversation,
          title: response.title,
        };
        dispatch(
          updateConversation({
            conversationId: selectedConversation._id,
            updatedConversation: updatedConversation,
          })
        );
      }
    }
    setIsLoading(false);
  }, [response]);

  const pollForMessage = (conversationId: string) => {
    setIsLoading(true);
    fetch(
      getEndpoint(Endpoint.GET_ALL_CHATS_OF_CONVERSATION, {
        id: conversationId,
      }),
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    ).then((response): any => {
      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((body: { data: { data: LoadMessagesResponse } }) => {
        setResponse(body?.data?.data);
      });
    });
  };

  return { pollForMessage, isLoading };
};
