import { AppRoutes } from "constants/routes";
import { useVerifyAccount } from "hooks/useVerifyAccount";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "styles/colors";
import {
  CenterContentOnPage,
  StyledButton,
  StyledButtonFullWidth,
  StyledForm,
  StyledSubtitle,
  StyledTitle,
} from "styles/sharedComponents";
import { LoadingScreen } from "./Loading/LoadingScreen.component";

const StyledBackground = styled(CenterContentOnPage)`
  background-color: ${Colors.BACKGROUND};
`;

const VerifiedText = styled(StyledSubtitle)`
  text-align: center;
`;

export const VerifyEmailComponent = () => {
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const { verifyAccount, isLoading } = useVerifyAccount();
  const { token } = useParams();

  const onFailure = () => navigate(AppRoutes.LOGIN);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    if (!token) {
      onFailure();
    } else {
      verifyAccount(token, onFailure);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <StyledBackground>
          <StyledForm>
            <StyledTitle>Email verified</StyledTitle>
            <VerifiedText>
              Your email address was successfully verified. <br />
              Click the button to log in.
            </VerifiedText>
            <StyledButtonFullWidth onClick={() => navigate(AppRoutes.LOGIN)}>
              Log in
            </StyledButtonFullWidth>
          </StyledForm>
        </StyledBackground>
      )}
    </>
  );
};
