import styled from "styled-components";
import { Colors } from "styles/colors";
import { CenterContentOnPage } from "styles/sharedComponents";
import { LoadingAnimation } from "./LoadingAnimation.component";

const StyledBackground = styled(CenterContentOnPage)`
  background-color: ${Colors.BACKGROUND};
`;

export const LoadingScreen = () => {
  return (
    <StyledBackground>
      <LoadingAnimation isLarge />
    </StyledBackground>
  );
};
