import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { String } from "aws-sdk/clients/acm";
import { getHeaders } from "helpers/auth";
import { ErrorCodes } from "constants/errorCodes";

interface SetNewPasswordInterface {
  token: string;
  password: string;
  confirmPassword: string;
}
export const useSetNewPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const setNewPassword = (props: SetNewPasswordInterface) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.SET_NEW_PASSWORD, { token: props.token }), {
      method: "PATCH",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        password: props.password,
        passwordConfirm: props.confirmPassword,
      }),
    }).then((response): any => {
      setIsLoading(false);
      if (response.ok) {
        setIsReset(true);
      } else {
        response.json().then((data: any) => {
          if (data.errorCode == ErrorCodes.INVALID_TOKEN) {
            setIsTokenInvalid(true);
          } else if (data.errorCode == ErrorCodes.INVALID_INPUT) {
            setIsTokenValid(true);
          }
        });
      }
    });
  };

  return { setNewPassword, isLoading, isReset, isTokenInvalid, isTokenValid };
};
