import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Chat, Conversation } from "interfaces/backend";

interface ConversationState {
  conversations: Conversation[];
  selectedConversation?: Conversation;
  messages: Chat[];
  loadingConversations: string[];
}

const initialState: ConversationState = {
  conversations: [],
  selectedConversation: undefined,
  messages: [],
  loadingConversations: [],
};

interface UpdateConversationPayload {
  conversationId: string;
  updatedConversation: Conversation;
}

interface UpdateMessagePayload {
  messageId: string;
  updatedMessage: Chat;
}

const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    addConversation: (state, action: PayloadAction<Conversation>) => {
      state.conversations.unshift(action.payload);
    },
    updateConversation: (
      state,
      action: PayloadAction<UpdateConversationPayload>
    ) => {
      const index = state.conversations.findIndex(
        (convo) => convo._id === action.payload.conversationId
      );
      if (index !== -1) {
        state.conversations[index] = action.payload.updatedConversation;
      }
    },
    deleteConversation: (state, action: PayloadAction<string>) => {
      state.conversations = state.conversations.filter(
        (convo) => convo._id !== action.payload
      );
    },
    setConversations: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = action.payload;
    },
    selectConversation: (
      state,
      action: PayloadAction<Conversation | undefined>
    ) => {
      state.selectedConversation = action.payload;
    },
    setMessages: (state, action: PayloadAction<Chat[]>) => {
      state.messages = action.payload;
    },
    updateMessage: (state, action: PayloadAction<UpdateMessagePayload>) => {
      const index = state.messages.findIndex(
        (message) => message._id === action.payload.messageId
      );
      if (index !== -1) {
        state.messages[index] = action.payload.updatedMessage;
      }
    },
    addMessage: (state, action: PayloadAction<Chat>) => {
      state.messages.push(action.payload);
    },
    addLoadingConversation: (state, action: PayloadAction<string>) => {
      state.loadingConversations.push(action.payload);
    },
    removeLoadingConversation: (state, action: PayloadAction<string>) => {
      const index = state.loadingConversations.indexOf(action.payload);

      if (index !== -1) {
        state.loadingConversations.splice(index, 1);
      }
    },
  },
});

export const {
  addConversation,
  updateConversation,
  deleteConversation,
  setConversations,
  selectConversation,
  updateMessage,
  setMessages,
  addMessage,
  addLoadingConversation,
  removeLoadingConversation,
} = conversationSlice.actions;
export default conversationSlice.reducer;
