import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TypewriterState {
  showTypewriter: boolean;
  displayText: string;
}

const initialState: TypewriterState = {
  displayText: "",
  showTypewriter: false,
};

const typewriterSlice = createSlice({
  name: "typewriter",
  initialState,
  reducers: {
    setDisplayText: (state, action: PayloadAction<string>) => {
      state.displayText = action.payload;
    },
    setShowTypeWriter: (state, action: PayloadAction<boolean>) => {
      state.showTypewriter = action.payload;
    },
  },
});

export const { setDisplayText, setShowTypeWriter } = typewriterSlice.actions;
export default typewriterSlice.reducer;
