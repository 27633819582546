import { useState } from "react";
import { getEndpoint, Endpoint } from "../constants/endpoints";
import { getHeaders } from "helpers/auth";

export const useSubmitFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const submitFeedback = (
    feedback: string,
    messageId?: string,
    promptId?: string
  ) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.SUBMIT_FEEDBACK), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        promptChatId: promptId,
        responseChatId: messageId,
        content: feedback,
      }),
    });
  };

  return { submitFeedback, isLoading };
};
