import UserIcon from "../UserIcon";
import styled from "styled-components";
import { Colors } from "../../styles/colors";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { useRef, useState } from "react";
import { Button } from "@mui/material";
import {
  StyledButton,
  StyledSecondaryButton,
} from "../../styles/sharedComponents";
import { Chat } from "interfaces/backend";

const Container = styled.div<{ editing: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.editing ? "95" : "30")}px;
`;

const EditIconContainer = styled.button<{ disabled: boolean }>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.disabled ? Colors.MID_ACCENT : Colors.ACCENT};
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover,
  &:active {
    background: ${Colors.MID_ACCENT};
  }
`;

const SpaceContainer = styled.div<{ editing: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: 220px;
  height: 10px;
  align-items: center;
  flex-shrink: 0;
  color: blue;

  @media (max-width: 959px) {
    width: 45px;
  }

  width: ${(props) => (props.editing ? "108" : "220")}px;
`;

const MessageContainer = styled.div<{ editing: boolean }>`
  display: flex;
  background-color: ${(props) =>
    props.editing ? Colors.ULTRA_LIGHT_ACCENT : Colors.ACCENT};
  border-radius: 50px;
  justify-content: flex-end;
  padding: 20px 0;
  width: ${(props) => (props.editing ? "100%" : "auto")};
  position: relative;

  @media (max-width: 959px) {
    border-radius: 35px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  padding-top: 8px;
  color: ${Colors.WHITE};
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: 17px;

  @media (max-width: 959px) {
    font-weight: 500;
  }
`;

const TextAreaContainer = styled.textarea`
  display: flex;
  margin-left: 44px;
  margin-top: 8px;
  color: ${Colors.BLACK};
  border: none;
  width: 100%;
  background: transparent;

  overflow-x: hidden;

  /* Scrollbar Track */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Scrollbar Handle/Thumb */
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.ACCENT}; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }

  /* Scrollbar Track */
  &::-webkit-scrollbar-track {
    background: transparent; /* Color of the scrollbar track */
  }

  /* Scrollbar Corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the scrollbar corner */
  }
`;

const UserIconContainer = styled.div`
  display: flex;
  padding-right: 22px;
  padding-left: 14px;
  //   padding: 10px 23px;
`;

const EditButtonsContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 80px;

  button {
    height: 50px;
    border; 1px solid black;
  }
`;

export const UserMessageComponent = (props: {
  message: Chat;
  canEdit: boolean;
}) => {
  const { message, canEdit } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message.content);

  const handleEdit = () => {
    setIsEditing(true);
    textareaRef.current?.focus();
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedMessage(message.content);
  };

  return (
    <Container editing={isEditing}>
      <SpaceContainer editing={isEditing}>
        {/* {canEdit && (
          <EditIconContainer disabled={isEditing} onClick={handleEdit}>
            <EditIcon></EditIcon>
          </EditIconContainer>
        )} */}
      </SpaceContainer>

      <MessageContainer key={message._id} editing={isEditing}>
        {isEditing ? (
          <>
            <TextAreaContainer
              ref={textareaRef}
              value={editedMessage}
              autoFocus={true}
              onChange={(e) => setEditedMessage(e.target.value)}
            />
            <EditButtonsContainer>
              <StyledSecondaryButton hasMargin onClick={handleCancelEdit}>
                Cancel
              </StyledSecondaryButton>
              <StyledButton>Update</StyledButton>
            </EditButtonsContainer>
          </>
        ) : (
          <TextContainer>{message.content}</TextContainer>
        )}

        <UserIconContainer>
          <UserIcon />
        </UserIconContainer>
      </MessageContainer>
    </Container>
  );
};
