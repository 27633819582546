import { useState } from "react";

export const useToggleBoolean = (defaultState = false) => {
  const [boolValue, setBoolValue] = useState(defaultState);

  const toggleBoolean = () => {
    setBoolValue(!boolValue);
  };

  return [boolValue, toggleBoolean] as const;
};
