import { useState } from "react";
import { Endpoint, getEndpoint } from "../constants/endpoints";
import { getHeaders } from "helpers/auth";

export const useSendVerificationToken = () => {
  const [isLoading, setIsLoading] = useState(false);
  const sendVerificationToken = (email: string, onComplete: () => void) => {
    setIsLoading(true);

    fetch(getEndpoint(Endpoint.SEND_VERIFICATION_TOKEN), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    }).then((response): any => {
      setIsLoading(false);
      response.json().then((data: any) => {
        console.log(data);
        onComplete();
      });
    });
  };

  return { sendVerificationToken, isLoading };
};
