import { useEffect, useRef, useState } from "react";
import {
  FullWidthTextInput,
  StyledForm,
  StyledButtonFullWidth,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
  CenterContentOnPage,
} from "../styles/sharedComponents";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "../assets/left-arrow.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Colors } from "styles/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { useSetNewPassword } from "hooks/useSetNewPassword";
import { LoadingScreen } from "./Loading/LoadingScreen.component";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one symbol"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
});

const BackToLoginLink = styled(StyledLink)`
  && {
    margin-top: 36px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${Colors.DARK_GRAY_TEXT};
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  margin-right: 4px;
`;

interface SetNewPasswordInfo {
  password: string;
  confirmPassword: string;
}

const StyledBackground = styled(CenterContentOnPage)`
  background-color: ${Colors.BACKGROUND};
`;

const Title = styled(StyledTitle)`
  margin-bottom: 24px;
`;

export const SetNewPasswordComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SetNewPasswordInfo>({ resolver: yupResolver(schema) });

  const { setNewPassword, isLoading, isReset, isTokenInvalid, isTokenValid } =
    useSetNewPassword();
  const navigate = useNavigate();
  const { token } = useParams();
  const onFailure = () => navigate(AppRoutes.LOGIN);

  useEffect(() => {
    if (!token) {
      onFailure();
    } else {
      setNewPassword({ token: token, password: "", confirmPassword: "" });
    }
  }, []);

  useEffect(() => {
    if (isTokenInvalid) {
      onFailure();
    }
  }, [isTokenInvalid]);

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successfulReset, setSuccessfulReset] = useState(false);

  const isDisabled = !password || !confirmPassword;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit: SubmitHandler<SetNewPasswordInfo> = (data) => {
    setNewPassword({
      ...data,
      token: token || "",
    });
  };

  const SetNewPasswordForm = (
    <>
      <Title>Set a new password</Title>
      <FullWidthTextInput
        {...register("password")}
        label="Password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
                style={{ color: "black" }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FullWidthTextInput
        label="Confirm Password"
        {...register("confirmPassword")}
        variant="outlined"
        type={showConfirmPassword ? "text" : "password"}
        error={!!errors.confirmPassword}
        helperText={
          errors.confirmPassword ? errors.confirmPassword.message : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={toggleConfirmPasswordVisibility}
                edge="end"
                style={{ color: "black" }}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <StyledButtonFullWidth
        type="submit"
        disabled={isDisabled}
        loading={isLoading}
      >
        Continue
      </StyledButtonFullWidth>
      <BackToLoginLink isunderlined={false} href="/login">
        <StyledLeftArrowIcon />
        Back to login
      </BackToLoginLink>
    </>
  );

  const SuccessPage = (
    <>
      <StyledTitle>Password reset</StyledTitle>
      <StyledSubtitle>Your password was successfully reset.</StyledSubtitle>
      <StyledButtonFullWidth onClick={onFailure}>Log in</StyledButtonFullWidth>
    </>
  );

  return (
    <StyledBackground>
      {!isTokenValid && !isTokenInvalid && <LoadingScreen />}
      {isTokenValid && (
        <CenterContentOnPage>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {isReset ? SuccessPage : SetNewPasswordForm}
          </StyledForm>
        </CenterContentOnPage>
      )}
    </StyledBackground>
  );
};
