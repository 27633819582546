import UserIcon from "../UserIcon";
import { Chat } from "../../interfaces/backend";
import styled from "styled-components";
import Typewriter from "../Typewriter.component";
import { botUser } from "../../constants/sender";
import { BotMessageComponent } from "./BotMessage.component";
import { UserMessageComponent } from "./UserMessage.component";

const MessageContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserIconContainer = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const MessageComponent = (props: {
  message: Chat;
  isLatest: boolean;
  previousMessage?: Chat;
}) => {
  const { message, isLatest } = props;

  if (message.role === "ASSISTANT") {
    return <BotMessageComponent {...props} />;
  } else {
    return <UserMessageComponent message={message} canEdit={isLatest} />;
  }
};
