import { TextField, Button } from "@mui/material";
import styled from "styled-components";
import Link from "@mui/material/Link";
import { Colors } from "../styles/colors";
import Gradient from "../assets/gradient-background.png";
import { forwardRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export const StyledTitle = styled.div<{ invert?: boolean }>`
  font-size: 40px;
  font-weight: 500;
  line-height: 54.56px;
  letter-spacing: 0.01em;
  color: ${(props) => (props.invert ? Colors.WHITE : Colors.BLACK)};
  font-family: "Newsreader", sans-serif;

  @media (max-width: 959px) {
    font-size: 32px;
  }
`;

export const StyledSubtitle = styled.div<{ invert?: boolean }>`
  font-size: 19px;
  font-weight: 500;
  line-height: 21.28px;
  margin-bottom: 35px;
  color: ${Colors.WHITE};
  color: ${(props) => (props.invert ? Colors.WHITE : Colors.BLACK)};
  @media (max-width: 959px) {
    font-size: 16px;
  }
`;

export const StyledLink = styled(Link)<{ isunderlined: boolean }>`
  && {
    font-weight: 600;
    color: ${Colors.WHITE};
    text-decoration: ${(props) =>
      props.isunderlined ? "isunderlined" : "none"};
    text-decoration-color: inherit;
    text-decoration-thickness: ${(props) =>
      props.isunderlined ? "1px" : "initial"};
  }
`;

export const StyledText = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 19.1px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const TextInput = forwardRef((props: any, ref) => (
  <StyledTextInput {...props} inputRef={ref} variant="filled">
    {props.children}
  </StyledTextInput>
));

export const StyledTextInput = styled(TextField)`
  && {
    margin-bottom: 24px;

    .MuiFilledInput-root {
      background-color: white; // Set background color to white
      border-radius: 12px;
      box-shadow: none; // Ensure no shadow is applied
      &:before,
      &:after {
        border: none; // Remove the default border lines
      }
    }

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline:after {
      border: none !important; // Ensure no bottom border
    }

    label {
      font-size: 16px;
      font-family: Spartan;
      color: ${Colors.BLACK}; // Default label color
    }

    input {
      font-size: 16px;
      font-family: Spartan;
      border-radius: 12px;
    }

    & .MuiFormHelperText-root.Mui-error {
      color: ${Colors.BRIGHT_RED};
      font-weight: 600;
    }
  }
`;

export const FullWidthTextInput = styled(TextInput)`
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  && {
    margin-bottom: 24px;

    fieldset {
      border: none;
    }
    label {
      font-size: 14px;
    }
    input {
      font-size: 14px;
      border-radius: 12px;
      background-color: ${Colors.INPUT_BACKGROUND};
    }
  }
`;

export const StyledButton = styled(LoadingButton)<{ disabled?: boolean }>`
  && {
    background-color: ${(props) =>
      props.disabled ? Colors.DISABLED : Colors.ACCENT};
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
    border-radius: 69px;
    color: ${Colors.WHITE};
    padding: 14px 46px;
    border: none;
    height: 50px;

    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    .MuiCircularProgress-root {
      color: ${Colors.WHITE};
    }

    &:hover,
    &:active {
      background-color: ${(props) =>
        props.disabled ? "auto" : Colors.MID_ACCENT};
    }
  }
`;

export const GradientButton = styled(StyledButton)`
  && {
    background: linear-gradient(
      to right,
      ${Colors.ACCENT},
      ${Colors.SECONDARY_ACCENT}
    );

    &:hover,
    &:active {
      background: linear-gradient(
        to right,
        ${Colors.DARK_ACCENT},
        ${Colors.DARK_SECONDARY_ACCENT}
      );
    }
  }
`;

export const StyledButtonFullWidth = styled(StyledButton)`
  width: 100%;
`;

export const StyledSecondaryButton = styled(Button)<{ hasMargin: boolean }>`
  height: 50px;
  padding: 14px;
  width: 132px;

  && {
    color: ${Colors.ACCENT};
    background-color: transparent;
    font-family: "Spartan", sans-serif;
    text-transform: none;
    font-weight: 800;
    font-size: 16px;
    border-radius: 69px;

    margin-right: ${(props) => (props.hasMargin ? "16px" : "0")};

    &:hover,
    &:active {
      background-color: ${Colors.SECONDARY_HOVER};
    }
  }
`;

export const StyledScrollBar = styled.div<{ width: number }>`
  overflow-y: auto;
  overflow-x: hidden;

  /* Scrollbar Track */
  &::-webkit-scrollbar {
    width: ${(props) => props.width};
  }

  /* Scrollbar Handle/Thumb */
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.ACCENT}; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
  }

  /* Scrollbar Track */
  &::-webkit-scrollbar-track {
    background: ${Colors.WHITE}; /* Color of the scrollbar track */
  }

  /* Scrollbar Corner */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Background color of the scrollbar corner */
  }
`;

export const StyledForm = styled.form`
  font-family: "Spartan", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
`;

export const CenterContentOnPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  padding: 30px;
`;

export const LeftAlignContent = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const RightAlignContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const GradientBackground = styled.div`
  width: 100vw;
  height: 100dvh;
  background-image: url(${Gradient});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ModalContainer = styled.div`
  background: ${Colors.WHITE};
  width: 756px;
  border-radius: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 35px;

  @media (max-width: 845px) {
    width: calc(100% - 40px);
  }
`;

export const ModalTitle = styled(StyledTitle)`
  font-weight: 400;
  font-size: 25px;
  color: ${Colors.BLACK};
  line-height: 31px;
`;

export const LandingLogo = styled.img`
  width: 276px;

  @media (max-width: 959px) {
    width: 195px;
  }
`;
