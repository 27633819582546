import {
  GradientBackground,
  GradientButton,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
  LandingLogo,
} from "styles/sharedComponents";
import Logo from "../assets/cbgpt-logo-white.png";
import styled from "styled-components";
import { Colors } from "styles/colors";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "constants/routes";

const StyledLogo = styled(LandingLogo)`
  margin-top: 63px;
  margin-bottom: 50px;

  @media (max-width: 959px) {
    margin-bottom: 30px;
  }
`;

const Header = styled(StyledTitle)`
  font-size: 65px;

  @media (max-width: 959px) {
    font-size: 31px;
  }
`;

const PromoHeader = styled(StyledTitle)`
  font-size: 25px;
  line-height: 65px;
  font-weight: 600;
`;

const PromoContent = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.7);
`;

const StyledContainer = styled(GradientBackground)`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 27px;
`;

const StyledButton = styled(GradientButton)`
  && {
    margin-top: 42px;
    margin-bottom: 52px;
  }

  @media (max-width: 959px) {
    && {
      margin-top: 42px;
      margin-bottom: 52px;
    }
  }
`;

const Promo = styled.div`
  width: 865px;
  background-color: ${Colors.WHITE};
  border-radius: 30px 30px 0px 0px;
  height: 100%;
  padding: 54px 90px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  @media (max-width: 959px) {
    width: 100%;
    padding: 30px 38px;
  }

  /* Hide scrollbar for WebKit browsers (e.g., Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar width */
    height: 0; /* Remove scrollbar height for horizontal scroll */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Make scrollbar handle transparent */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Make scrollbar track transparent */
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent; /* Make scrollbar corner transparent */
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  scrollbar-color: transparent transparent; /* Make scrollbar transparent in Firefox */
`;

//   &::before {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: calc(100%);
//     height: 110px;

//     background: linear-gradient(
//       to bottom,
//       rgba(240, 241, 242, 0),
//       rgba(240, 241, 242, 0.8)
//     );

const Login = styled(StyledLink)`
  position: absolute;
  top: 49px;
  right: 72px;
  color: ${Colors.WHITE};
  cursor: pointer;

  @media (max-width: 1160px) {
    top: 26px;
    right: 30px;
  }
`;

const Paragraphs = [
  "Juneteenth, often referred to as Freedom Day or Emancipation Day, is a deeply significant event in American history. It's celebrated annually on the 19th of June, marking the day in 1865 when enslaved African Americans in Galveston, Texas were officially informed of their freedom by Union General Gordon Granger. This happened a full two and a half years after President Lincoln’s Emancipation Proclamation had formally abolished slavery. The belated delivery of this pivotal news is emblematic of the enduring struggle for civil rights and the ceaseless battle against racial oppression. It's a vivid reminder of the Black community's resilience in confronting systemic injustices.",
  "Commemorating Juneteenth isn't just about remembering the past, it’s about celebrating the strength and cultural richness of the Black community. It's a time to honor the fight for freedom and equality that has shaped African American history and culture. Moreover, it's an opportunity to educate people about the legacy of slavery and its ongoing impact on present-day racial disparities, encouraging action towards racial justice.\n\nBy recognizing Juneteenth, we not only acknowledge a critical moment in Black history, but we also show our solidarity with the Black community. We're reminded of the importance of reflecting on our past, celebrating societal progress, and committing to actively address ongoing racial inequalities. This day underlines the essentiality of remembering our history, while continuously advocating for social justice and equal rights for all.",
];
export const LandingPageComponent = () => {
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <StyledLogo src={Logo} alt="Logo" />
      <Header invert>Culturally Informed AI</Header>
      <StyledButton onClick={() => navigate(AppRoutes.SIGN_UP)}>
        Sign Up
      </StyledButton>
      <Promo>
        <PromoHeader>What is Juneteenth?</PromoHeader>

        {Paragraphs.map((paragraph, index) => (
          <PromoContent key={index}>{paragraph}</PromoContent>
        ))}
      </Promo>
      <Login isunderlined={false} onClick={() => navigate(AppRoutes.LOGIN)}>
        Login
      </Login>
    </StyledContainer>
  );
};
