import { IconButton, Button, Slide, SlideProps } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as ErrorIcon } from "../assets/error.svg";
import styled from "styled-components";
import { Colors } from "styles/colors";

const SnackbarContent = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.LIGHT_ERROR_RED};
  border: 3px solid ${Colors.ERROR_RED};
  border-radius: 14px;
  padding: 8px;
  width: 338px;
`;

const ErrorMessage = styled.span`
  margin-left: 8px;
  flex-grow: 1;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  width: 37px;
  height: 37px;
  flex-shrink: 0;
`;

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

const defaultMessage =
  "Something went wrong. Please refresh or try again later";

export const useErrorToast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultMessage);

  const openErrorToast = (message?: string) => {
    setErrorMessage(message || defaultMessage);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ErrorToast = () => (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent>
        <StyledErrorIcon />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </SnackbarContent>
    </Snackbar>
  );

  return { openErrorToast, ErrorToast };
};
