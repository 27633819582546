import { Endpoint, getEndpoint } from "constants/endpoints";
import {
  Chat,
  Conversation,
  CreateConversationResponse,
  SendMessageResponse,
} from "interfaces/backend";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConversation,
  selectConversation,
  updateConversation,
  updateMessage,
  setMessages,
  addMessage,
  addLoadingConversation,
  removeLoadingConversation,
} from "../helpers/conversationSlice";
import { getCurrentDateTimeString } from "helpers/dateFormatter";
import { AppState } from "interfaces/redux";
import { setShowTypeWriter } from "helpers/typewriterSlice";
import { getHeaders } from "helpers/auth";
import { useCreateConversation } from "./useCreateConversation";

export const useManageConversation = () => {
  const selectedConversation = useSelector(
    (state: AppState) => state.conversationsData.selectedConversation
  );

  const [response, setResponse] = useState<SendMessageResponse | undefined>(
    undefined
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!response) {
      return;
    }

    dispatch(
      removeLoadingConversation(response.data.promptChat.conversationId)
    );

    if (response.data.promptChat.conversationId === selectedConversation?._id) {
      dispatch(
        updateMessage({
          messageId: "tempMessage",
          updatedMessage: response.data.promptChat,
        })
      );
      dispatch(addMessage(response.data.responseChat));
      dispatch(setShowTypeWriter(true));
    }
  }, [response]);

  const { createConversation } = useCreateConversation();

  const sendMessage = (message: string) => {
    const tempMessage: Chat = {
      _id: "tempMessage",
      role: "USER",
      content: message,
      createdAt: getCurrentDateTimeString(),
      conversationId: "temp",
    };
    dispatch(addMessage(tempMessage));

    if (!selectedConversation) {
      createConversation(message);
      return;
    }

    dispatch(addLoadingConversation(selectedConversation._id));

    fetch(getEndpoint(Endpoint.CREATE_CHAT, { id: selectedConversation._id }), {
      method: "POST",
      credentials: "include",
      headers: getHeaders(),
      body: JSON.stringify({
        content: message,
        mlContextId: selectedConversation.mlContextId,
      }),
    }).then((response): any => {
      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((data: SendMessageResponse) => {
        setResponse(data);
      });
    });
  };

  //   const truncateMessage = () => {
  //     const updatedMessage: Chat = {
  //       ...messages[messages.length - 1],
  //       content: displayText,
  //     };
  //     setMessages([...messages.slice(0, -1), updatedMessage]);
  //     setShowTypewriter(false);

  //     fetch(getEndpoint(Endpoint.UPDATE_CHAT, { id: updatedMessage._id }), {
  //       method: "POST",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         content: displayText,
  //       }),
  //     });
  //   };

  const truncateMessage = () => {};

  return {
    sendMessage,
    truncateMessage,
  };
};
