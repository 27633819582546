import { Endpoint, getEndpoint } from "constants/endpoints";
import { getHeaders } from "helpers/auth";
import { setMessages } from "helpers/conversationSlice";
import { Chat } from "interfaces/backend";
import { useDispatch } from "react-redux";

export const useLoadMessages = () => {
  const dispatch = useDispatch();

  const loadMessages = (conversationId: string) => {
    dispatch(setMessages([]));

    fetch(
      getEndpoint(Endpoint.GET_ALL_CHATS_OF_CONVERSATION, {
        id: conversationId,
      }),
      {
        method: "GET",
        credentials: "include",
        headers: getHeaders(),
      }
    ).then((response): any => {
      if (!response.ok) {
        console.log("error");
        console.log(response.status);
        console.log(response.statusText);
      }

      response.json().then((data: { data: { data: { chats: Chat[] } } }) => {
        dispatch(setMessages(data.data?.data?.chats || []));
      });
    });
  };

  return loadMessages;
};
